<template>
    <div class="teamIntro">
        <div class="listArea">
            <div class="item">
                <div class="wrapItem">
                    <div>
                        <img class="picA" :src="jsPic.a">
                    </div>
                    <div class="contentArea">
                        <div class="titleT">
                            我们的使命
                        </div>
                        <div class="contentT">
                            <div>
                                我们相信每一个人都有享受优质教育的权力，我们相信“快乐的学习”是学习的最高境界，
                                教育应该倡导在轻松愉快的学习体验进行，学中玩，玩中学，而不是“艰苦奋斗，死记硬背”。
                            </div>
                            <div>
                                我们的使命是让高性价比、高效卓越的外语教育人人皆可享有。
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="wrapItem">
                    <div class="contentArea">
                        <div class="titleT">
                            我们的愿景
                        </div>
                        <div class="contentT">
                            <div>
                                我们的愿景是培养全球畅行的未来公民。我们相信个人的潜能，我们更加无比地坚信：
                                语言教育的本质是为追求“诗和远方”的梦想家提供一张通行证。
                            </div>
                            <div>
                                我们无比相信语言的魅力，我们相信学习的力量。
                            </div>
                        </div>
                    </div>
                    <div>
                        <img class="picA" :src="jsPic.b">
                    </div>
                </div>
            </div>

            <div class="item specialItem">
                <div class="wrapItem">
                    <div>
                        <img class="picA" :src="jsPic.c">
                    </div>
                    <div class="contentArea">
                        <div class="titleT">
                            我们的价值观
                        </div>
                        <div class="contentT">
                            <div>
                                以用户体验为首位，提供专业高效的语言学习解决方案。严谨务实、开放包容，在不断探索中发现更广阔的世界。
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="wrapItem">
                    <div class="contentArea">
                        <div class="titleT">
                            鹿老师说外语团队
                        </div>
                        <div class="contentT">
                            <div>
                                我们热爱外语教学，术业有专攻。精益求精，细节控，通过科学设计，让你的外语学习体验更有趣。
                            </div>
                        </div>
                    </div>
                    <div>
                        <img class="picA" :src="jsPic.d">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style src="./TeamIntro.scss" lang="scss"></style>

<script>
    import Constants from "../../../../utils/Constants"

    export default {
        data() {
            return {
                jsPic: Constants.landPagePic.second_rank.js
            }
        }
    }
</script>

